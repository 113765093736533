import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"

export const DicaExtraWrapper = styled.section`
  background: var(--alert_back);
  display: flex;
  padding: 1rem;
  color: var(--white);
  justify-content: space-between;

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const DicaExtraTextArea = styled.div`
  width: 80%;
  ${media.lessThan("large")`
    width: 100%;
    margin-bottom: 1rem;
  `}
`

export const DicaExtraTitle = styled.h1`
  font-size: 1.5rem;
  text-align: left;
  font-family: Poppins, sans-serif;
  margin-bottom: 1rem;

  ${media.lessThan("large")`
    text-align: center;
  `}
`
export const DicaExtraText = styled.p`
  line-height: 1.2;
  ${media.lessThan("large")`
    text-align: center;
  `}
`

export const DicaExtraExternalLink = styled.a`
  width: 100%;
  color: var(--alert_button);
  text-decoration: none;
  justify-self: flex-end;
  margin-top: 1rem;
  display: block;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${media.lessThan("large")`
    color: var(--white);
    background: var(--alert_button);
    padding:1rem;
    border-radius: .8rem;
    width: 100%;
  `}
`

export const DicaExtraImage = styled(Img)`
  max-width: 125px;
  width: 100%;
  height: auto;

  ${media.lessThan("large")`
    max-width: 100%
  `}
`
