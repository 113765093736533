import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const AsideBar = () => {
  const { facebookImage, colorindo, combo } = useStaticQuery(
    graphql`
      query {
        facebookImage: file(relativePath: { eq: "facebook-page.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        colorindo: file(relativePath: { eq: "colorindo-fanart.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        combo: file(relativePath: { eq: "combo-ultimate-x.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <S.AsideBarWrapper>
      <S.AsideBox
        href="https://www.facebook.com/Desenhando-Anime-110905487087032"
        target="_blank"
      >
        <S.AsideBoxImage
          fluid={facebookImage.childImageSharp.fluid}
          alt="Imagem do Luffy que leva a página do facebook"
        />
        <S.AsideBoxText>Facebook</S.AsideBoxText>
      </S.AsideBox>
      <S.AsideBox
        bgColor="var(--green_logo)"
        border="10px solid var(--green_logo_hover)"
        shadow="inset -30rem 0 0 0 var(--green_logo_hover)"
        href="https://go.hotmart.com/E32329732K?src=sidebarSite"
        target="_blank"
      >
        <S.AsideBoxImage
          fluid={colorindo.childImageSharp.fluid}
          alt="Imagem de um desenho colorido para promover o curso colorindo fan art"
        />
        <S.AsideBoxText>Colorindo Fan Art</S.AsideBoxText>
      </S.AsideBox>
      <S.AsideBox
        bgColor="var(--orange_logo)"
        border="10px solid var(--orange_logo_hover)"
        shadow="inset -30rem 0 0 0 var(--orange_logo_hover)"
        href="https://go.hotmart.com/G40189893I?src=sidebarSite"
        target="_blank"
      >
        <S.AsideBoxImage
          fluid={combo.childImageSharp.fluid}
          alt="Imagem sobre o combo ultimate com todos os cursos."
        />
        <S.AsideBoxText>Combo Ultimate</S.AsideBoxText>
      </S.AsideBox>
    </S.AsideBarWrapper>
  )
}

export default AsideBar
