import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"

export const AsideBarWrapper = styled.aside`
  width: 27.5%;
  margin-left: 2.5%;
  padding: 2.5rem 0;
  background-color: var(--white);
  max-height: 50vh;
  position: sticky;
  top: 3rem;
  border-radius: 0.5rem;

  ${media.lessThan("large")`
    width: 100%;
    position: relative;
    display: block;
    margin: 0;
    max-height: none;
    top: 1rem;
    padding: 1rem 0;
    margin-bottom: 1rem;
  `}
`

export const AsideBox = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--facebook);
  background-color: ${props =>
    props.bgColor ? props.bgColor : "var(--facebook)"};
  overflow: hidden;
  border-radius: 0.5rem;
  justify-content: start;
  border-right: ${props =>
    props.border ? props.border : "10px solid var(--facebook_hover)"};
  transition: box-shadow 1.75s;
  margin: 0 0 2rem;

  &:hover {
    box-shadow: ${props =>
      props.shadow ? props.shadow : "inset -30rem 0 0 0 var(--facebook_hover)"};
  }
`

export const AsideBoxImage = styled(Img)`
  width: 120px;
  height: 120px;
  position: absolute;
  left: -50px;
  border-radius: 50%;
`
export const AsideBoxText = styled.p`
  color: var(--white);
  font-size: 1.5rem;
  text-align: center;
`
