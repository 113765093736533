import styled from "styled-components"
import media from "styled-media-query"

export const LayoutWrapper = styled.section`
  display: flex;
  padding: 2rem 5rem;
  background-color: #f1f1f1;
  min-height: 70vh;

  ${media.lessThan("large")`
    flex-direction: column;
    padding: 1rem;
  `}
`

export const LayoutMain = styled.main`
  width: 70%;
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 0.5rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 10rem 0 1rem;
  `}
`
