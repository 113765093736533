import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const DicaExtra = () => {
  const { dicaExtraImg } = useStaticQuery(graphql`
    query {
      dicaExtraImg: file(
        relativePath: { eq: "metodo-fan-art-dica-extra.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.DicaExtraWrapper>
      <S.DicaExtraTextArea>
        <S.DicaExtraTitle>Dica Extra</S.DicaExtraTitle>
        <S.DicaExtraText>
          Se você busca uma forma de aperfeiçoar seus traços ou mesmo iniciar do
          zero e aprender a criar seus desenhos favoritos. Você precisa conhecer
          o Método Fan Art 3.0
        </S.DicaExtraText>
        <S.DicaExtraExternalLink
          href="http://bit.ly/aprendendo-desenhar-dica-extra"
          target="_blank"
        >
          Clique Aqui e conheça Agora
        </S.DicaExtraExternalLink>
      </S.DicaExtraTextArea>
      <S.DicaExtraImage
        fluid={dicaExtraImg.childImageSharp.fluid}
        alt="Naruto com o pergaminho do jutsu das sombras"
      />
    </S.DicaExtraWrapper>
  )
}

export default DicaExtra
