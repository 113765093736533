import React from "react"
import SimpleReactLightbox from "simple-react-lightbox"

import TopAlert from "../TopAlert"
import Header from "../Header"
import AsideBar from "../AsideBar"
import Footer from "../Footer"

import GlobalStyles from "../../styles/global"
import * as S from "./styled"

const Layout = ({ children }) => {
  return (
    <>
      <SimpleReactLightbox>
        <GlobalStyles />
        <TopAlert
          text="Não perca tempo !!! Aprenda a desenhar agora com o Método Fan Art 3.0"
          link="http://bit.ly/aprendendo-desenhar-fan-art-top"
          txtBtn="Quero Aprender"

        />
        <Header />
        <S.LayoutWrapper>
          <S.LayoutMain>{children}</S.LayoutMain>
          <AsideBar />
        </S.LayoutWrapper>
        <Footer />
      </SimpleReactLightbox>
    </>
  )
}

export default Layout
